import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import media from 'styles/media';
import { useI18n } from 'services/i18n';
import { Background, Container, Row, Col } from 'styles/grid';
import Layout from 'components/layout';
import SEO from 'components/seo';
import NonStretchedImage from 'components/non-stretched-image.js';
import Links from 'components/links.js';
import { SecondaryButton } from 'components/buttons';
import {
  PrimaryTitle,
  BlockTitle,
  Text,
  QuoteText,
  ParagraphTitle,
  SecondaryTitle,
} from 'components/texts';
import Quote from 'images/icons/quote.svg';

const FEEDBACK_CARDS = [
  { feedback: 'mudjeansOne', gender: 'male' },
  { feedback: 'lejaby', gender: 'female' },
  { feedback: 'jennyfer', gender: 'female' },
  { feedback: 'officine', gender: 'male' },
  { feedback: 'undiz', gender: 'female' },
  { feedback: 'balzacOne', gender: 'female' },
  { feedback: 'balzacTwo', gender: 'female' },
  { feedback: 'mudjeansTwo', gender: 'male' },
  { feedback: 'atelierUnes', gender: 'female' },
];

const Card = styled.div`
  padding: 20px;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  align-items: start;

  svg {
    width: 1.5rem;
  }
`;

const CardText = styled.div`
  height: 100%;
  margin-left: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    margin: 0;
  }
`;

const FeedbackCard = ({ t, feedback, gender }) => (
  <Card>
    <Quote />
    <CardText>
      <Text>{t(`blog.user-voice-sessions.feedbacks.${feedback}.text`)}</Text>
      <Text>
        {t(`blog.user-voice-sessions.feedbacks.${gender}Consumer`)}
        <Text weight="bold" as="span">
          {t(`blog.user-voice-sessions.feedbacks.${feedback}.company`)}
        </Text>
      </Text>
    </CardText>
  </Card>
);

const Section = styled.section`
  ${media.lg`
    padding: 0 15%;
  `}
`;

const ResponsiveCenteredCol = styled(Col)`
  text-align: center;
  ${media.md`
    text-align:inherit;
  `}
`;

const FeedbacksGrid = styled.div`
  width: 100%;
  display: grid;
  gap: 20px 20px;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(9, auto);

  ${media.md`
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, auto);
  `}

  ${media.lg`
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto);
  `}
`;

const UserVoiceSessions = () => {
  const [t, language] = useI18n();
  const data = useStaticQuery(graphql`
    query {
      userVoice: file(relativePath: { eq: "blog/uservoice.png" }) {
        childImageSharp {
          fluid(maxWidth: 820, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      userRatings: file(relativePath: { eq: "blog/userratings.png" }) {
        childImageSharp {
          fluid(maxWidth: 820, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const markupHelper = {
    context: 'http://schema.org',
    type: 'Article',
    name: t('blog.user-voice-sessions.title'),
    datePublished: t('blog.user-voice-sessions.date'),
    image: 'https://fitle.com' + data.userVoice.childImageSharp.fluid.src,
  };

  return (
    <Layout>
      <SEO
        title={t('blog.user-voice-sessions.title')}
        description={t('blog.user-voice-sessions.description')}
        lang={language}
        markupHelper={markupHelper}
      />
      <Section style={{ marginBottom: 20 }}>
        <Container>
          <Row style={{ marginBottom: 60 }}>
            <ResponsiveCenteredCol sm={12} md={12}>
              <SecondaryButton to="/blog" text={t('blog.index')} $backButton />
            </ResponsiveCenteredCol>
          </Row>
          <Row>
            <Col sm={12} md={12} style={{ marginBottom: 100 }}>
              <PrimaryTitle>{t('blog.user-voice-sessions.title')}</PrimaryTitle>
              <Text weight="bold">{t('blog.user-voice-sessions.date')}</Text>
            </Col>
            <Col sm={12} md={12}>
              <BlockTitle>
                <span
                  role="img"
                  aria-label="notes emoji"
                  style={{ verticalAlign: 'text-bottom', marginLeft: 0 }}
                >
                  📝
                </span>
                {t('blog.user-voice-sessions.subtitleOne')}
              </BlockTitle>
              <Text mobileAlign="left">{t('blog.user-voice-sessions.textOne')}</Text>
              <Text mobileAlign="left">{t('blog.user-voice-sessions.textTwo')}</Text>
              <Text mobileAlign="left">
                {t('blog.user-voice-sessions.textThree')}
                <Text weight="bold" as="span">
                  {t('blog.user-voice-sessions.textThreeBold')}
                </Text>
              </Text>
              <NonStretchedImage
                fluid={data.userVoice.childImageSharp.fluid}
                alt={t('blog.user-voice-sessions.imageAlt')}
                style={{ overflow: 'inherit', margin: '60px 0' }}
              />
            </Col>
            <Col sm={12} md={12} style={{ marginBottom: 40 }}>
              <BlockTitle>
                <span
                  role="img"
                  aria-label="people emoji"
                  style={{ verticalAlign: 'text-bottom', marginLeft: 0 }}
                >
                  👥
                </span>
                {t('blog.user-voice-sessions.subtitleTwo')}
              </BlockTitle>
              <ParagraphTitle mobileAlign="left">
                1.
                <span role="img" aria-label="100 emoji" style={{ verticalAlign: 'text-bottom' }}>
                  💯
                </span>
                {t('blog.user-voice-sessions.listOne')}
              </ParagraphTitle>
              <Text mobileAlign="left">{t('blog.user-voice-sessions.textFour')}</Text>
              <QuoteText>{t('blog.user-voice-sessions.quoteOne')}</QuoteText>
              <NonStretchedImage
                fluid={data.userRatings.childImageSharp.fluid}
                alt={t('blog.user-voice-sessions.altTwo')}
                style={{ overflow: 'inherit', margin: '60px 0' }}
              />
              <ParagraphTitle mobileAlign="left">
                2.
                <span
                  role="img"
                  aria-label="speaking emoji"
                  style={{ verticalAlign: 'text-bottom' }}
                >
                  🗣
                </span>
                {t('blog.user-voice-sessions.listTwo')}
              </ParagraphTitle>
              <Text mobileAlign="left">{t('blog.user-voice-sessions.textFive')}</Text>
            </Col>
            <Col sm={12} md={12}>
              <SecondaryTitle>{t('blog.user-voice-sessions.feedbacks.title')}</SecondaryTitle>
            </Col>
          </Row>
        </Container>
      </Section>
      <Background style={{ padding: '40px 0' }}>
        <Section style={{ margin: '0 auto' }}>
          <FeedbacksGrid>
            {FEEDBACK_CARDS.map(({ feedback, gender }) => (
              <FeedbackCard key={feedback} t={t} feedback={feedback} gender={gender} />
            ))}
          </FeedbacksGrid>
        </Section>
      </Background>
      <Section style={{ marginTop: 20 }}>
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <ParagraphTitle mobileAlign="left">
                3.
                <span role="img" aria-label="phone emoji" style={{ verticalAlign: 'text-bottom' }}>
                  📱
                </span>
                {t('blog.user-voice-sessions.listThree')}
              </ParagraphTitle>
              <Text mobileAlign="left">{t('blog.user-voice-sessions.textSix')}</Text>
              <Text mobileAlign="left" weight="bold">
                {t('blog.user-voice-sessions.textSeven')}
              </Text>
              <Text mobileAlign="left">
                <span
                  role="img"
                  aria-label="star emoji"
                  style={{ verticalAlign: 'text-bottom', marginLeft: 0 }}
                >
                  ⭐️
                </span>
                <Text weight="bold" as="span">
                  {t('blog.user-voice-sessions.reco.textOneBold')}
                </Text>
                {t('blog.user-voice-sessions.reco.textOne')}
              </Text>
              <Text mobileAlign="left">
                <Text weight="bold" as="span">
                  {t('blog.user-voice-sessions.reco.textTwoBold')}
                </Text>
                {t('blog.user-voice-sessions.reco.textTwo')}
              </Text>
              <Text mobileAlign="left">{t('blog.user-voice-sessions.reco.textThree')}</Text>
              <Text mobileAlign="left">
                <span
                  role="img"
                  aria-label="star emoji"
                  style={{ verticalAlign: 'text-bottom', marginLeft: 0 }}
                >
                  ⭐️
                </span>
                <Text weight="bold" as="span">
                  {t('blog.user-voice-sessions.sizingChart.textOneBold')}
                </Text>
                {t('blog.user-voice-sessions.sizingChart.textOne')}
              </Text>
              <Text mobileAlign="left">
                <Text weight="bold" as="span">
                  {t('blog.user-voice-sessions.sizingChart.textTwoBold')}
                </Text>
                {t('blog.user-voice-sessions.sizingChart.textTwo')}
              </Text>
            </Col>
          </Row>
        </Container>
      </Section>
      <Links withTitle={true} links={['plugin', 'dashboard']} />
    </Layout>
  );
};

export default UserVoiceSessions;
